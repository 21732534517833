import React, { useEffect, useState } from 'react';
import qrImage from '../Assets/Projects/3TT-QR.png'; // Assicurati che il percorso sia corretto
import newGameImage from '../Assets/Projects/3TacToe.png'; // Assicurati che il percorso sia corretto
import './PopupModal.css'; // Assicurati che il percorso sia corretto

const PopupModal = ({ onClose }) => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    // Funzione per determinare se l'utente è su un dispositivo mobile
    const checkIfMobile = () => {
      setIsMobile(/Mobi|Android/i.test(navigator.userAgent));
    };

    checkIfMobile();
    window.addEventListener('resize', checkIfMobile);

    // Funzione per gestire il clic esterno
    const handleOutsideClick = (e) => {
      if (e.target.id === 'popup-modal') onClose();
    };

    window.addEventListener('click', handleOutsideClick);

    // Cleanup
    return () => {
      window.removeEventListener('resize', checkIfMobile);
      window.removeEventListener('click', handleOutsideClick);
    };
  }, [onClose]);

  return (
    <div id="popup-modal" className="popup-modal">
      <div className="popup-content">
        <button className="close-btn" onClick={onClose}>&times;</button>
        <h2>Let's Play My New Mobile Game:</h2>
        {isMobile ? (
          <>
            <img src={newGameImage} alt="New Game" style={{ width: '100%', height: 'auto' }} />
            <a href="https://sanliuk.com/3TacToe" target="_blank" rel="noopener noreferrer">
              <button className="download-btn">App Store</button>
            </a>
          </>
        ) : (
          <img src={qrImage} alt="QR Code" style={{ width: '100%', height: 'auto' }} />
        )}
      </div>
    </div>
  );
};

export default PopupModal;
