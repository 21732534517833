import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import slkspace from "../../Assets/Projects/slkspace.png";
import TMDD from "../../Assets/Projects/TMDD.jpeg";
import youtube from "../../Assets/Projects/youtube.png";
import ThreeTacToe from "../../Assets/Projects/3ttWhite.png";
import RunLiuk from "../../Assets/Projects/RunLiuk.png";
function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          My Recent <strong className="purple">Works </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few projects I've worked on recently.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>

        <Col md={4} className="project-card">
            <ProjectCard
              imgPath={ThreeTacToe}
              isBlog={false}
              title="3 Tac Toe"
              description="This game is a good twist to the classic Tic Tac Toe! It's like the traditional Tic Tac Toe but developed in 3 dimensions. It is available for for mobile and tablet. (For iOS & Android)"
              ghLink="/3TacToe"
            />
          </Col>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={slkspace}
              isBlog={false}
              title="SLK Space"
              description="This is my original space shooter survival videogame with 10 levels. It's both in 2D and 3D. This game is available for for mobile and tablet. (For iOS & Android)"
              ghLink="/app"
              //demoLink="/games"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={RunLiuk}
              isBlog={false}
              title="Run Liuk"
              description="A simple classic casual game. This game is available for for mobile and tablet. (Available for Android)"
              ghLink="https://play.google.com/store/apps/details?id=com.DefaultCompany.testrunliuk"
              //demoLink="/games"
            />
          </Col>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={TMDD}
              isBlog={false}
              title="The Maimed Dumb Duck"
              description="NFT collectibles with perks for videogames. A combination of 3D colored ducks with special skills. (OpenSea Collection)"
              ghLink="https://opensea.io/collection/the-sanliuk-maimeddumbduck"
              demoLink="https://www.sanliuk.com/TMDD/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={youtube}
              isBlog={false}
              title="YouTube"
              description="This is my YouTube channel where I shere new animations, music videos and news with everyone."
              ghLink="https://www.youtube.com/channel/UCgbG6HYcITXhZ0W35lUvFbA"
            />
          </Col>



        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
